import { useRef, useState } from 'react';
import { useChain, useSpring } from 'react-spring';
import matches from 'matches';
import { CHAT_HISTORY_DURATION, HEADER_DURATION, INPUT_AREA_DURATION } from 'animationTiming';

let ANIMATION_DELAYS = [0, 1.8, 2, 2.6, 2.6];

if (!matches) {
    ANIMATION_DELAYS = ANIMATION_DELAYS.map(() => 0);
}

/**
 * Hook for general initial chat animation
 */
export function useChatAnimations() {
    const [chatAnimationEnd, setChatAnimationEnd] = useState(false);

    const chatRef = useRef();
    const inputRef = useRef();
    const headerRef = useRef();
    const verticalDividerRef = useRef();
    const horizontalDividerRef = useRef();

    const headerAnimationProps = useSpring({
        to: async (next) => {
            await next({ opacity: 1 });
            await next({ transform: 'translateY(0)' });
        },
        from: {
            opacity: 0,
            transform: 'translateY(42vh)',
        },
        config: {
            duration: HEADER_DURATION,
        },
        immediate: !matches,
        ref: headerRef,
    });

    const chatAnimationProps = useSpring(
        {
            opacity: 1,
            transform: 'translateY(0)',
            from: {
                opacity: 0,
                transform: 'translateY(100%)',
            },
            immediate: !matches,
            config: {
                duration: CHAT_HISTORY_DURATION,
            },
            ref: chatRef,
            onRest: () => {
                setChatAnimationEnd(true);
            },
        },
    );
    const inputAnimationProps = useSpring({
        opacity: 1,
        transform: 'translateY(0)',
        from: {
            opacity: 0,
            transform: 'translateY(100%)',
        },
        immediate: !matches,
        config: {
            duration: INPUT_AREA_DURATION,
        },
        ref: inputRef,
    });

    const verticalDividerAnimationProps = useSpring({
        opacity: 1,
        from: {
            opacity: 0,
        },
        immediate: !matches,
        ref: verticalDividerRef,
    });

    const horizontalDividerAnimationProps = useSpring({
        width: '100%',
        from: {
            width: '0%',
        },
        immediate: !matches,
        ref: horizontalDividerRef,
    });

    useChain(
        [headerRef, chatRef, inputRef, horizontalDividerRef, verticalDividerRef],
        ANIMATION_DELAYS.map(d => d / 2),
    );

    if (chatAnimationEnd) {
        inputAnimationProps.transform = 'unset';
    }

    return {
        headerAnimationProps,
        chatAnimationProps,
        inputAnimationProps,
        verticalDividerAnimationProps,
        horizontalDividerAnimationProps,
    };
}
