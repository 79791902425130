import React, { useContext } from 'react';
import { useChatAnimations } from './useChatAnimations';
import Chat from './Chat/Chat';


/**
 * Animations context
 */
const AnimationsContext = React.createContext({
    headerAnimationProps: undefined,
    inputAnimationProps: undefined,
    chatAnimationProps: undefined,
    verticalDividerAnimationProps: undefined,
    horizontalDividerAnimationProps: undefined,
});

/**
 * Animations context hook
 */
export function useAnimationsContext() {
    return useContext(AnimationsContext);
}


function ChatContainer() {
    const animationProps = useChatAnimations();

    return (
        <AnimationsContext.Provider value={animationProps}>
            <Chat />
        </AnimationsContext.Provider>
    );
}

export default ChatContainer;
