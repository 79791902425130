import { useEffect, useState } from 'react';
import { provideChatIdentity, storeIdentity } from 'hooks/useChatIdentity/utils';
import { useChatApi } from 'hooks/useChatApi/useChatApi';

/**
 * User identity hook.
 * @returns {{synced: boolean, id: (null|string)}}
 */
export function useChatIdentity() {
    const [identity, setIdentity] = useState(
        provideChatIdentity(),
    );

    /**
     * Handle identity sync.
     */
    function onIdentitySynced(payload) {
        setIdentity({ id: payload.id, synced: true });
    }

    const chatApi = useChatApi({ onIdentitySynced });

    useEffect(() => {
        if (!identity.synced) {
            chatApi.syncIdentity(identity);
        }
    }, [identity]);

    useEffect(
        () => storeIdentity(identity),
        [identity],
    );

    return identity;
}
