import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Slide from '@material-ui/core/Slide';
import theme from 'materialTheme';
import { MD_BREAKPOINT } from 'matches';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { MESSAGES_TEMPLATES } from 'bot-knowledge';
import styles from './MenuContainerStyles';

function MenuContainer({ sendPreparedQuestion, classes }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const matches = window.innerWidth < MD_BREAKPOINT;

    /**
     * Handles question asking
     *
     * @param question
     * @returns {Function}
     */
    const answerHandler = question => () => {
        sendPreparedQuestion(question);
        setIsMenuOpen(false);
        setIsSidebarScrolled(false);
    };

    const [isSidebarScrolled, setIsSidebarScrolled] = useState(false);
    const [suggestionsSlide, setSuggestionsSlide] = useState(false);
    const questionsContainerRef = useRef(null);

    /**
     * Scroll to bottom on messages updates
     */
    useEffect(() => {
        const questionsContainerEl = questionsContainerRef.current;

        if (questionsContainerEl != null && isMenuOpen) {
            questionsContainerEl.scrollTo(0, questionsContainerEl.scrollHeight);
        }
    }, [isMenuOpen]);

    return (
        <div className={classes.menuContainer}>
            <IconButton
                onClick={() => setIsMenuOpen(true)}
                role="presentation"
                color="inherit"
            >
                <MenuIcon fontSize="large" />
            </IconButton>
            <Slide
                direction="left"
                in={isMenuOpen}
                mountOnEnter
                unmountOnExit
                onEnter={() => setSuggestionsSlide(true)}
                onExited={() => setSuggestionsSlide(false)}
                timeout={matches ? 0 : theme.transitions.duration.short}
            >
                <div className={classes.sidebar}>
                    <AppBar position="static">
                        <ToolBar className={classes.toolBar}>
                            <Typography color="inherit" variant="body1">
                                <Link
                                    href="mailto:contact@craftigence.com"
                                    color="inherit"
                                    underline="none"
                                    className={classes.link}
                                >
                                    Contact via email
                                </Link>
                            </Typography>
                        </ToolBar>
                    </AppBar>
                    <div
                        ref={questionsContainerRef}
                        className={classes.container}
                        onScroll={({ target }) => {
                            // eslint-disable-next-line
                            const isScrolled = target.scrollHeight - target.scrollTop - target.offsetHeight > 0;

                            setIsSidebarScrolled(isScrolled);
                        }}
                    >
                        <div className={classes.innerContainer}>
                            {MESSAGES_TEMPLATES
                                .filter(({ isAvailableInMenu }) => isAvailableInMenu)
                                .map((question, i) => (
                                    <Slide
                                        in={suggestionsSlide}
                                        direction="up"
                                        timeout={matches ? theme.transitions.duration.short : 0}
                                        /* eslint-disable-next-line */
                                        key={`${i}-msg-template`}
                                    >
                                        <div className={classes.displayFlex}>
                                            <aside>
                                                <Chip
                                                    onClick={answerHandler(question)}
                                                    role="presentation"
                                                    label={question.userQuestion}
                                                    variant="outlined"
                                                    color="secondary"
                                                    className={classes.chip}
                                                />
                                            </aside>
                                        </div>

                                    </Slide>
                                ))}
                        </div>
                    </div>
                    <IconButton
                        onClick={() => {
                            setIsMenuOpen(false);
                            setIsSidebarScrolled(false);
                        }}
                        role="presentation"
                        color="inherit"
                        className={`${classes.closeButton} ${isSidebarScrolled && classes.closeButtonHighlight}`}
                    >
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </div>
            </Slide>
        </div>
    );
}

MenuContainer.propTypes = {
    sendPreparedQuestion: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuContainer);
