export const MESSAGE_WAITING = 'MESSAGE_WAITING'; // Waiting for sending
export const MESSAGE_FAILED = 'MESSAGE_FAILED'; // Sending failed
export const MESSAGE_SENDING = 'MESSAGE_SENDING'; // Message sending
export const MESSAGE_DELIVERED = 'MESSAGE_DELIVERED'; // Deliver status received from server
export const MESSAGE_CLIENT = 'MESSAGE_CLIENT'; // Message only for client (not needed to be synced with server)

export const SENDER_USER = 'user';
export const SENDER_BOT = 'bot';

export const MESSAGE_TIMEOUT = 6000;
