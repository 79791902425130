import { createMuiTheme } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import common from '@material-ui/core/colors/common';
import transitions from '@material-ui/core/styles/transitions';

export default createMuiTheme({
    palette: {
        primary: {
            main: grey[900],
        },
        secondary: {
            main: grey[600],
        },
        background: {
            default: common.white,
        },
    },
    overrides: {
        MuiSvgIcon: {
            fontSizeLarge: {
                fontSize: 32,
            },
            fontSizeSmall: {
                fontSize: 18,
            },
        },
        MuiIconButton: {
            root: {
                borderRadius: '0',
                padding: 16,

                '&:hover': {
                    backgroundColor: 'var(--default-grey-color)',
                },
            },
        },
        MuiChip: {
            root: {
                borderRadius: 11,
                borderBottomRightRadius: '0',
                height: 'auto',
                transition: transitions.create(['background-color', 'box-shadow', 'color']),
            },
            label: {
                paddingTop: 10,
                paddingRight: 16,
                paddingBottom: 12,
                paddingLeft: 16,
                fontSize: 20,
                letterSpacing: '.4px',
                color: 'currentColor',
                userSelect: 'unset',
            },
            outlinedSecondary: {
                borderWidth: 2,
                color: common.white,
            },
            colorSecondary: {
                backgroundColor: 'var(--default-grey-color)',
                color: 'inherit',
            },
        },
        MuiInputBase: {
            input: {
                fontSize: 20,
                paddingLeft: 16,
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: common.black,
            },
        },
        MuiFab: {
            root: {
                backgroundColor: common.white,
                boxShadow: 'none',
                border: '2px solid var(--default-grey-color)',

                '&:hover': {
                    backgroundColor: 'var(--default-grey-color)',
                },
            },
        },
    },
    typography: {
        fontFamily: [
            '"Bai Jamjuree"',
            'sans-serif',
        ].join(','),
        fontSize: 20,
        body1: {
            fontSize: 20,
        },
        body2: {
            fontSize: 15,
        },
        useNextVariants: true,
    },
    props: {
        MuiIconButton: {
            disableRipple: true,
        },
    },
});
