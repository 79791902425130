import uuid from 'uuid/v4';

const IDENTITY_STORAGE_KEY = 'chatIdentity';


/**
 * Store identity.
 * @param identity
 */
export function storeIdentity(identity) {
    localStorage.setItem(IDENTITY_STORAGE_KEY, JSON.stringify(identity));
}

/**
 * Get chat identity
 * @returns {{synced: boolean, id: null|string}}
 */
export function provideChatIdentity() {
    const storedIdentity = localStorage.getItem(IDENTITY_STORAGE_KEY);
    let identity = {
        id: null,
        synced: false,
    };

    if (storedIdentity) {
        identity = JSON.parse(storedIdentity);
    } else {
        identity = {
            id: uuid(),
            synced: false,
        };
        storeIdentity(identity);
    }

    return identity;
}
