import { useEffect, useState } from 'react';
import {
    createBotMessage,
    createUserMessage,
    getMessagesHistory,
    messageFactory,
    storeMessageHistory,
} from 'hooks/useMessages/utils';
import {
    MESSAGE_CLIENT,
    MESSAGE_DELIVERED,
    MESSAGE_FAILED,
    MESSAGE_SENDING,
    MESSAGE_TIMEOUT,
    MESSAGE_WAITING,
    SENDER_USER,
} from 'hooks/useMessages/constants';
import { useChatApi } from 'hooks/useChatApi/useChatApi';
import uuid from 'uuid/v4';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { INTENTS_URL_MAP, MESSAGES_TEMPLATES } from 'bot-knowledge';
import { useChatIdentity } from '../useChatIdentity/useChatIdentity';

/**
 * Hook for messages
 *
 * @returns {{sendPreparedQuestion: sendPreparedQuestion, writeMessage: writeMessage, messages}}
 */
export function useMessages(initialIntentUrl = null) {
    const [messages, setMessages] = useState(getMessagesHistory);
    const [currentIntent, setCurrentIntent] = useState(null);
    const identity = useChatIdentity();
    const chatApi = useChatApi({
        onMessage,
        onDelivered,
    });

    const prevMessageCount = usePrevious(messages.length);

    useEffect(syncMessagesWithStorage, [messages]);
    useEffect(() => {
        if (identity.synced) {
            processSend(messages.length !== prevMessageCount);
        }
    }, [messages.length, identity.synced]);

    useEffect(() => {
        if (initialIntentUrl) {
            const entry = Object.entries(INTENTS_URL_MAP)
                .find(([, url]) => initialIntentUrl === url);
            const template = entry && MESSAGES_TEMPLATES.find(({ intent }) => intent === entry[0]);

            if (template && template.intent) {
                setCurrentIntent(template.intent);
            }

            if (template && !messages.slice(-8).find(m => m.intent === template.intent)) {
                sendPreparedQuestion(template);
            }
        }
    }, []);

    /**
     * Update current intent.
     * @param intent
     */
    function updateCurrentIntent(intent) {
        const intentUrl = INTENTS_URL_MAP[intent];
        setCurrentIntent(intent);
        window.dataLayer.push({
            event: 'userDetectedIntent',
            intent,
        });
        if (intentUrl) {
            window.history.pushState({}, '', `/${intentUrl}`);
        }
    }

    /**
     * On new message.
     * @param {{id: string, intent: string}} message
     */
    function onMessage(message) {
        setCurrentIntent(currentIntent || null);
        addMessage(createBotMessage(identity.id, message.intent));

        chatApi.sendReceivedReport(message.id);

        updateCurrentIntent(message.intent);
    }

    /**
     * On message delivered.
     * @param deliveredMessage
     */
    function onDelivered(deliveredMessage) {
        setMessages(state => state.map((message) => {
            if (message.id === deliveredMessage.id) {
                return {
                    ...message,
                    status: MESSAGE_DELIVERED,
                };
            }

            return message;
        }));
    }

    /**
     * Check that message is failed
     * @param failedMessage
     */
    function checkMessageFailed(failedMessage) {
        setMessages(currentMessages => currentMessages.map((message) => {
            if (message.id === failedMessage.id && message.status === MESSAGE_SENDING) {
                return {
                    ...message,
                    status: MESSAGE_FAILED,
                };
            }

            return message;
        }));
    }

    /**
     * Sync messages with storage.
     */
    function syncMessagesWithStorage() {
        storeMessageHistory(messages);
    }

    /**
     * Updates current messages
     * Set local history
     * @param {{id: string, content: string, chat: string, sender: string, status: string}} message
     */
    function addMessage(message) {
        return setMessages(
            currentMessages => [...currentMessages, message],
        );
    }

    /**
     * Create and send new message.
     *
     * @param {string} content
     * @param {string} sender
     * @param {string} status
     */
    function writeMessage(content, sender = SENDER_USER, status = MESSAGE_WAITING) {
        if (sender === SENDER_USER) {
            window.dataLayer.push({ event: 'userSendsMessage' });
        }

        addMessage(messageFactory(
            uuid(),
            sender,
            identity.id,
            content,
            status,
        ));
    }

    /**
     * Resends the message
     * @param messageId
     */
    function resendMessage(messageId) {
        const message = messages.find(({ id }) => id === messageId);

        if (message === undefined || message.status !== MESSAGE_FAILED) {
            return;
        }

        setMessages(currentMessages => currentMessages.filter(mes => mes.id !== messageId));

        writeMessage(message.content);
        processSend(true);
    }

    /**
     * Process all non-sent messages.
     */
    function processSend(isSendEvent = false) {
        const statusesToSkip = [MESSAGE_DELIVERED, MESSAGE_CLIENT, MESSAGE_FAILED];

        if (isSendEvent) {
            statusesToSkip.push(MESSAGE_SENDING);
        }

        setMessages(state => state.map((message) => {
            if (statusesToSkip.includes(message.status)) {
                return message;
            }

            try {
                chatApi.sendMessage(message);

                setTimeout(() => checkMessageFailed(message), MESSAGE_TIMEOUT);

                return {
                    ...message,
                    status: MESSAGE_SENDING,
                };
            } catch (e) {
                return {
                    ...message,
                    status: MESSAGE_FAILED,
                };
            }
        }));
    }

    /**
     * Sends prepared question and answer for it
     */
    function sendPreparedQuestion({ userQuestion, intent }) {
        const userMessage = createUserMessage(identity.id, userQuestion, MESSAGE_CLIENT);
        const botMessage = createBotMessage(identity.id, intent);

        addMessage(userMessage);
        // Queue this update for another call stack (fixes scroll)
        setTimeout(() => addMessage(botMessage), 0);
        updateCurrentIntent(botMessage.intent);

        chatApi.sendTemplateMessage(userMessage, botMessage);
    }

    return {
        currentIntent,
        messages,
        resendMessage,
        writeMessage,
        sendPreparedQuestion,
    };
}
