const styles = theme => ({
    historyContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        overflowY: 'auto',

        '&::-webkit-scrollbar': {
            display: 'none',
        },
        'scrollbar-width': 'none',
    },

    grid: {
        display: 'grid',
        gridTemplateAreas: '"header chat aside"',
        gridTemplateColumns: 'var(--sidebar-width) 1fr var(--sidebar-width)',
        alignContent: 'stretch',
        height: '100%',

        [theme.breakpoints.down('md')]: {
            gridTemplateAreas: `"header header"
                                "chat aside"`,
            gridTemplateColumns: '1fr var(--sidebar-width)',
            gridTemplateRows: 'auto 1fr',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateAreas: `"header"
                                "chat"`,
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'auto 1fr',
        },
    },

    header: {
        gridArea: 'header',
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
        borderRight: 'var(--border-style)',

        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing.unit * 2}px`,
            borderRight: '0',
        },
    },

    chat: {
        gridArea: 'chat',
        borderRight: 'var(--border-style)',

        [theme.breakpoints.down('sm')]: {
            borderRight: '0',
        },
    },

    aside: {
        gridArea: 'aside',
    },
});

export default styles;
