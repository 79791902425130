import PropTypes from 'prop-types';
import { SENDER_BOT, SENDER_USER } from 'hooks/useMessages/constants';

export const MessageType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    sender: PropTypes.oneOf([
        SENDER_USER,
        SENDER_BOT,
    ]).isRequired,
    content: PropTypes.string,
    intent: PropTypes.string,
});
