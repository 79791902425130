import React from 'react';
import { animated } from 'react-spring';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useMessages } from 'hooks/useMessages/useMessages';
import Header from 'components/ChatContainer/Header/Header';
import { useAnimationsContext } from 'components/ChatContainer/ChatContainer';
import ChatHistory from './ChatHistory/ChatHistory';
import InputArea from './InputArea/InputArea';
import styles from './ChatStyles';

const AnimatedGrid = animated(Grid);

const AnimatedHeader = animated(Header);

function Chat({ classes }) {
    const initialIntent = window.location.pathname.slice(1);
    const {
        currentIntent,
        messages,
        resendMessage,
        writeMessage,
        sendPreparedQuestion,
    } = useMessages(initialIntent || null);
    const {
        inputAnimationProps,
        chatAnimationProps,
        headerAnimationProps,
    } = useAnimationsContext();

    return (
        <div className={classes.grid}>
            <AnimatedHeader className={classes.header} style={headerAnimationProps} />

            <Grid container direction="column" className={classes.chat}>
                <AnimatedGrid
                    xs
                    item
                    className={classes.historyContainer}
                    style={chatAnimationProps}
                >
                    <ChatHistory
                        currentIntent={currentIntent}
                        handleResend={resendMessage}
                        messages={messages}
                    />

                    {/* TODO: Add offline banner */}
                    {/* <OfflineBanner /> */}
                </AnimatedGrid>
                <AnimatedGrid item style={inputAnimationProps}>
                    <InputArea
                        sendMessage={writeMessage}
                        sendPreparedQuestion={sendPreparedQuestion}
                    />
                </AnimatedGrid>
            </Grid>
            <div className={classes.aside} />
        </div>
    );
}

Chat.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Chat);
