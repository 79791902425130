import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { WebsocketProvider } from 'components/WebsocketProvider/WebsocketProvider';
import { withStyles } from '@material-ui/core';
import ChatContainer from './components/ChatContainer/ChatContainer';
import theme from './materialTheme';
import styles from './AppStyles';

function App() {
    return (
        <WebsocketProvider url={process.env.REACT_APP_WS_URL}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <ChatContainer />
            </MuiThemeProvider>
        </WebsocketProvider>
    );
}

export default withStyles(styles)(App);
