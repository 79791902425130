import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ApiGatewayWebSocket from 'aws-api-gateway-websocket';

/**
 * Websocket context.
 * @type {React.Context<{isWsOpened: boolean, ws: undefined}>}
 */
const WebsocketContext = React.createContext({
    ws: undefined,
    isWsOpened: false,
    isOnline: true,
});

/**
 * Use websocket.
 */
export function useWs() {
    return useContext(WebsocketContext);
}

/**
 * Websocket provider.
 * @param children
 * @param url
 * @returns {*}
 * @constructor
 */
export function WebsocketProvider({ children, url }) {
    const [ws] = useState(() => new ApiGatewayWebSocket(url));

    return (
        <WebsocketContext.Provider value={{ ws }}>
            {children}
        </WebsocketContext.Provider>
    );
}

WebsocketProvider.propTypes = {
    children: PropTypes.node.isRequired,
    url: PropTypes.string.isRequired,
};
