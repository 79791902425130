import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography/index';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress/index';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import styles from './MessageStatusStyles';


function MessageStatus({ classes, isFailed, isDelivered, handleResend }) {
    return (
        <div className={classes.container}>
            {isFailed && (
                <Grid container justify="flex-end" spacing={8}>
                    <Grid item>
                        <Typography variant="body2" color="textSecondary">
                            Failed to send.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            disableRipple
                            onClick={handleResend}
                            className={classes.button}
                        >
                            <RefreshIcon fontSize="small" className={classes.buttonIcon} />
                            <span>Retry</span>
                        </Button>
                    </Grid>
                </Grid>
            )}

            {!isDelivered && !isFailed && (
                <Grid container alignItems="flex-end" justify="flex-end" spacing={8}>
                    <Grid item>
                        <CircularProgress color="secondary" size={14} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" color="textSecondary">
                            Sending...
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

MessageStatus.propTypes = {
    classes: PropTypes.object.isRequired,
    isFailed: PropTypes.bool.isRequired,
    isDelivered: PropTypes.bool.isRequired,
    handleResend: PropTypes.func.isRequired,
};

export default withStyles(styles)(MessageStatus);
