import { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { SENDER_BOT, SENDER_USER } from 'hooks/useMessages/constants';

export function useChatHistoryScroll(messages) {
    const historyRef = useRef(null);
    const lastMessageRef = useRef(null);
    const [isOnBottom, setIsOnBottom] = useState(true);
    const [showScrollButton, setShowScrollButton] = useState(false);

    /**
     * Calculate next scroll position.
     * @param force
     * @returns {*}
     */
    function calculateScrollDistance(force = false) {
        const historyEl = historyRef.current;
        const lastMessage = lastMessageRef.current;
        const lastMessageHeight = lastMessage ? lastMessage.offsetHeight : 0;
        let scrollDistance = 0;
        const lastIndex = messages.length - 1;

        if (force) {
            scrollDistance = historyEl.scrollHeight;
        } else if (
            // eslint-disable-next-line max-len
            // In case if bot message is a response to user's message - show last string of user's message
            messages[lastIndex].sender === SENDER_BOT
            && lastIndex > 0
            && messages[lastIndex - 1].sender === SENDER_USER
        ) {
            scrollDistance = historyEl.scrollHeight - lastMessageHeight - 40;
        } else {
            scrollDistance = historyEl.scrollHeight;
        }

        return scrollDistance;
    }

    /**
     * Scroll to bottom.
     * @param {boolean} force
     * @param {boolean} smooth
     */
    function scrollBottom(force = false, smooth = false) {
        const historyElement = historyRef.current;

        if (!historyElement) {
            return;
        }

        historyElement.scrollTo({
            top: calculateScrollDistance(force),
            [smooth && 'behavior']: 'smooth',
        });
    }

    /**
     * Check if last message sent by user.
     * @returns {boolean}
     */
    function isLastMessageSentByUser() {
        const lastIndex = messages.length - 1;

        return lastIndex >= 0 && messages[lastIndex].sender === SENDER_USER;
    }

    /**
     * Handle history update.
     */
    function handleHistoryUpdate() {
        const forceScroll = isLastMessageSentByUser();

        if (isOnBottom || isLastMessageSentByUser()) {
            scrollBottom(forceScroll);
        } else {
            setShowScrollButton(true);
        }
    }

    /**
     * Handle scroll.
     * @param target
     */
    function onScroll({ target }) {
        const onBottom = target.scrollHeight - target.scrollTop - target.offsetHeight < 40;

        if (onBottom) {
            setShowScrollButton(false);
        }

        setIsOnBottom(onBottom);
    }

    useEffect(handleHistoryUpdate, [messages.length]);

    useLayoutEffect(() => {
        setTimeout(scrollBottom, 0);
    }, []);

    return {
        onScroll,
        scrollBottom,
        showScrollButton,
        historyRef,
        lastMessageRef,
    };
}
