const divider = {
    backgroundColor: 'var(--default-grey-color)',
    position: 'absolute',
    top: 0,
};

const style = {
    micButton: {
        position: 'relative',

        '& $verticalDivider': {
            right: 0,
        },
    },

    menuButton: {
        position: 'relative',

        '& $verticalDivider': {
            left: 0,
        },
    },

    inputContainer: {
        position: 'relative',
    },

    horizontalDivider: {
        ...divider,
        width: '0%',
        height: 'var(--divider-thickness)',
        right: 0,
        direction: 'rtl',
    },

    verticalDivider: {
        ...divider,
        width: 'var(--divider-thickness)',
        height: '100%',
    },
};

export default style;
