import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Init gtm data layer.
window.dataLayer = window.dataLayer || [];
// Scroll polyfill
smoothscroll.polyfill();

ReactDOM.render(<App />, document.getElementById('root'));


// eslint-disable-next-line no-console
console.log(`App version: ${process.env.REACT_APP_VERSION}`);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
