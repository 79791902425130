import uuid from 'uuid/v4';
import { MESSAGE_DELIVERED, MESSAGE_WAITING, SENDER_BOT, SENDER_USER } from 'hooks/useMessages/constants';
import { INITIAL_BOT_MESSAGE } from 'bot-knowledge';

const HISTORY_STORAGE_KEY = 'messagesHistory';

/**
 * Store message history.
 * @param {Array} messages
 */
export function storeMessageHistory(messages) {
    localStorage.setItem(
        HISTORY_STORAGE_KEY,
        JSON.stringify(messages),
    );
}

/**
 * Get local message history
 * @returns {Array}
 */
export function getMessagesHistory() {
    const storedMessages = localStorage.getItem(HISTORY_STORAGE_KEY);
    let messages = [createBotMessage('', '', INITIAL_BOT_MESSAGE)];

    if (storedMessages) {
        try {
            messages = JSON.parse(storedMessages);
        } catch (e) {
            // leave as is
        }
    }

    return Array.isArray(messages) ? messages : [];
}


/**
 * Create message
 *
 * @param {string} id
 * @param {string} sender
 * @param {string} chat
 * @param {string} content
 * @param {string} status
 * @param intent
 *
 * @returns {{id: string, content: string, chat: string, sender: string, status: string}}
 */
export function messageFactory(
    id,
    sender,
    chat,
    content = '',
    status = MESSAGE_DELIVERED,
    intent = null,
) {
    return {
        id,
        content,
        status,
        sender,
        chat,
        intent,
    };
}

/**
 * Creates bot message
 *
 * @param chat
 * @param intent
 * @param content
 */
export function createBotMessage(chat, intent, content = null) {
    return messageFactory(
        uuid(),
        SENDER_BOT,
        chat,
        intent ? null : content,
        MESSAGE_DELIVERED,
        intent,
    );
}

export function createUserMessage(chat, content, status = MESSAGE_WAITING) {
    return messageFactory(
        uuid(),
        SENDER_USER,
        chat,
        content,
        status,
    );
}
