const styles = theme => ({
    msgContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        '-webkit-overflow-scrolling': 'touch',
        'scrollbar-width': 'thin',
        'scrollbar-color': 'var(--default-grey-color) transparent',
    },
    msgInnerContainer: {
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px 0`,

        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px 0`,
        },
    },
    scrollButton: {
        position: 'fixed',
        top: 'auto',
        right: theme.spacing.unit * 2,
        bottom: theme.spacing.unit * 2,
        left: 'auto',
        transform: 'translate3d(0, 0, 0)',
    },
    hideScrollButton: {
        display: 'none',
    },
});

export default styles;
