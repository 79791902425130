import React from 'react';
import MicIcon from '@material-ui/icons/Mic';
import IconButton from '@material-ui/core/IconButton';

function MicrophoneContainer() {
    return (
        <IconButton color="inherit">
            <MicIcon fontSize="large" />
        </IconButton>
    );
}

export default MicrophoneContainer;
