import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { SENDER_BOT, SENDER_USER } from 'hooks/useMessages/constants';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Fab from '@material-ui/core/Fab';
import { useChatHistoryScroll } from 'components/ChatContainer/Chat/ChatHistory/useChatHistoryScroll';
import Message from './Message/Message';
import styles from './ChatHistoryStyles';

function ChatHistory({ messages, classes, handleResend, currentIntent, ...animationProps }) {
    const {
        onScroll,
        scrollBottom,
        showScrollButton,
        historyRef,
        lastMessageRef,
    } = useChatHistoryScroll(messages);

    const mainMessageIndex = currentIntent
        ? messages.findIndex(m => m.intent === currentIntent)
        : null;

    return (
        <div
            onScroll={onScroll}
            ref={historyRef}
            className={classes.msgContainer}
            {...animationProps}
        >
            <div className={classes.msgInnerContainer}>
                {messages.map((message, index) => (
                    <Message
                        isMainElement={mainMessageIndex === index}
                        forwardedRef={lastMessageRef}
                        message={message}
                        key={message.id}
                        handleResend={handleResend}
                        isChatOwnerMessage={SENDER_USER === message.sender}
                    />
                ))}
            </div>
            <Fab
                size="medium"
                color="inherit"
                type="button"
                disableRipple
                className={`${classes.scrollButton} ${!showScrollButton && classes.hideScrollButton}`}
                onClick={() => scrollBottom(true, true)}
            >
                <KeyboardArrowDownIcon fontSize="large" />
            </Fab>
        </div>
    );
}

ChatHistory.propTypes = {
    currentIntent: PropTypes.string,
    handleResend: PropTypes.func.isRequired,
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            chat: PropTypes.string,
            content: PropTypes.string,
            intent: PropTypes.string,
            sender: PropTypes.oneOf([SENDER_USER, SENDER_BOT]),
            status: PropTypes.string,
        }),
    ).isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChatHistory);
