import React, { useState, useRef } from 'react';
import { animated } from 'react-spring';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useAnimationsContext } from 'components/ChatContainer/ChatContainer';
import Hidden from '@material-ui/core/Hidden';
import MicrophoneContainer from './MicrophoneContainer/MicrophoneContainer';
import MenuContainer from './MenuContainer/MenuContainer';
import styles from './InputAreaStyles';

const ENTER_KEYCODE = 13;

function InputArea({ sendMessage, sendPreparedQuestion, classes }) {
    const [inputValue, setInputValue] = useState('');
    const inputEl = useRef(null);

    const {
        verticalDividerAnimationProps,
        horizontalDividerAnimationProps,
    } = useAnimationsContext();

    function onChange(e) {
        setInputValue(e.target.value);
    }

    /**
     * Handles message send logic
     * @param e
     */
    function sendHandler(e) {
        if (e.type !== 'click' && (e.shiftKey || e.keyCode !== ENTER_KEYCODE)) {
            return;
        }

        e.preventDefault();

        const message = inputValue && inputValue.trim();

        if (message) {
            sendMessage(message);
            setInputValue('');

            inputEl.current.focus();
        }
    }

    return (
        <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.inputContainer}
        >
            <animated.div
                style={horizontalDividerAnimationProps}
                className={classes.horizontalDivider}
            />
            <Hidden xsUp>
                <Grid item className={classes.micButton}>
                    <MicrophoneContainer />
                    <animated.div
                        style={verticalDividerAnimationProps}
                        className={classes.verticalDivider}
                    />
                </Grid>
            </Hidden>
            <Grid item xs>
                <FormControl fullWidth>
                    <InputBase
                        value={inputValue}
                        onChange={onChange}
                        onKeyDown={sendHandler}
                        inputRef={inputEl}
                        placeholder="Type a message..."
                        endAdornment={(
                            <InputAdornment position="end">
                                {inputValue && (
                                    <IconButton
                                        type="button"
                                        onClick={sendHandler}
                                        color="inherit"
                                    >
                                        <SendIcon fontSize="large" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item className={classes.menuButton}>
                <animated.div
                    style={verticalDividerAnimationProps}
                    className={classes.verticalDivider}
                />
                <MenuContainer
                    sendPreparedQuestion={sendPreparedQuestion}
                />
            </Grid>
        </Grid>
    );
}

InputArea.propTypes = {
    sendMessage: PropTypes.func.isRequired,
    sendPreparedQuestion: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InputArea);
