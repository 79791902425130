const fontXSStyle = {
    fontSize: 'var(--font-size-xs)',
    letterSpacing: '.6px',
    lineHeight: 1.2,
};

const fontSStyle = {
    fontSize: 'var(--font-size-s)',
    letterSpacing: '.6px',
};

const fontMStyle = {
    fontSize: 'var(--font-size-m)',
    lineHeight: 1.36,
    letterSpacing: '.6px',
};

const fontLStyle = {
    fontSize: 'var(--font-size-l)',
    lineHeight: 1.5,
    letterSpacing: '.7px',
};

const fontXLStyle = {
    fontSize: 'var(--font-size-xl)',
    lineHeight: 1.43,
    letterSpacing: '.8px',
};

const messageText = {
    maxWidth: 'var(--message-max-width)',
    whiteSpace: 'normal',
};

const styles = theme => ({
    container: {
        padding: `${theme.spacing.unit}px 0`,

        '&:last-of-type': {
            paddingBottom: theme.spacing.unit * 5,

            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.spacing.unit * 3,
            },
        },
    },

    container_ownerUser: {
        textAlign: 'right',

        '& + $container_ownerSystem': {
            paddingTop: `${theme.spacing.unit * 4}px`,

            [theme.breakpoints.down('sm')]: {
                paddingTop: `${theme.spacing.unit * 1.5}px`,
            },
        },
    },

    container_ownerSystem: {
        '& + $container_ownerUser': {
            paddingTop: `${theme.spacing.unit * 4}px`,

            [theme.breakpoints.down('sm')]: {
                paddingTop: `${theme.spacing.unit * 1.5}px`,
            },
        },
    },

    message: {
        display: 'inline-block',
        wordBreak: 'break-word',
        maxWidth: `calc(100% - ${theme.spacing.unit * 2}px)`,
        textAlign: 'left',

        '& br': {
            fontSize: 0,
        },
    },

    message_ownerUser: {
        margin: '0 0 0 auto',
    },

    message_ownerSystem: {
        margin: '0 auto 0 0',
        background: 'transparent',
    },

    messageText_ownerUser: {
        ...messageText,

        [theme.breakpoints.up('md')]: {
            ...fontLStyle,
        },
        [theme.breakpoints.down('md')]: {
            ...fontSStyle,
        },
        [theme.breakpoints.down('sm')]: {
            ...fontMStyle,
        },
        [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
            ...fontXSStyle,
        },
        [theme.breakpoints.down('xs')]: {
            ...fontXSStyle,
        },
    },

    messageText_ownerSystem: {
        ...messageText,
        padding: '0',
        color: 'inherit',

        [theme.breakpoints.up('md')]: {
            ...fontXLStyle,
        },
        [theme.breakpoints.down('md')]: {
            ...fontLStyle,
        },
        [theme.breakpoints.down('sm')]: {
            ...fontMStyle,
        },
        [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
            ...fontXSStyle,
        },
        [theme.breakpoints.down('xs')]: {
            ...fontXSStyle,
        },
    },
});

export default styles;
