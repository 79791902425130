import common from '@material-ui/core/colors/common';

const styles = theme => ({
    menuContainer: {
        marginRight: `-${theme.spacing.unit * 8}px`,

        [theme.breakpoints.down('sm')]: {
            marginRight: '0',
        },
    },
    sidebar: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        width: 'var(--sidebar-width)',
        color: common.white,
        background: common.black,

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    closeButton: {
        position: 'absolute',
        bottom: 0,
        transition: theme.transitions.create(['background-color', 'color']),
        margin: theme.spacing.unit * 1.5,
        padding: theme.spacing.unit,
        borderRadius: '50%',

        '&:hover': {
            backgroundColor: 'transparent',
        },
        [theme.breakpoints.down('sm')]: {
            right: 0,
        },
    },
    closeButtonHighlight: {
        backgroundColor: common.white,
        color: theme.palette.primary.main,

        '&:hover': {
            backgroundColor: common.white,
        },
    },
    chip: {
        margin: `0 ${theme.spacing.unit * 3}px 20px`,
    },
    toolBar: {
        justifyContent: 'flex-end',
    },
    container: {
        overflowY: 'auto',
        height: '100%',
        '-webkit-overflow-scrolling': 'touch',

        '@media (any-pointer: fine)': {
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            'scrollbar-width': 'none',
        },
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column-reverse',
        padding: `${theme.spacing.unit * 1.5}px 0 84px`,
        minHeight: '100%',
        overflowY: 'auto',

        '&::-webkit-scrollbar': {
            display: 'none',
        },
        'scrollbar-width': 'none',
    },
    link: {
        borderBottom: '1px solid currentColor',
    },
    displayFlex: {
        textAlign: 'right',
    },
});

export default styles;
