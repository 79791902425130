const styles = theme => ({
    '@global': {
        ':root': {
            '--default-grey-color': '#f0f2f4',
            '--default-dark-grey-color': '#949597',
            '--warning-color': '#ffdb61',
            '--divider-thickness': '2px',
            '--border-style': '2px solid #f0f2f4',
            '--sidebar-width': '360px',
            '--message-max-width': '60ch',
            '--font-size-xs': '16px',
            '--font-size-s': '20px',
            '--font-size-m': '22px',
            '--font-size-l': '24px',
            '--font-size-xl': '28px',
        },
        'html, body, .main': {
            height: '100%',

            '&::-webkit-scrollbar': {
                display: 'none',
            },
            'scrollbar-width': 'none',
        },
        body: {
            letterSpacing: '.4px',
        },
        '@media (any-pointer: fine)': {
            '::-webkit-scrollbar': {
                width: theme.spacing.unit * 2,
            },
            '::-webkit-scrollbar-button': {
                display: 'none',
            },
            '::-webkit-scrollbar-track': {
                opacity: 0,
            },
            '::-webkit-scrollbar-thumb': {
                background: 'transparent',
                boxShadow: `inset 0 0 ${theme.spacing.unit}px ${theme.spacing.unit}px var(--default-grey-color)`,
                border: `solid ${theme.spacing.unit / 2}px transparent`,
            },
            '::-webkit-scrollbar-thumb:hover': {
                boxShadow: `inset 0 0 ${theme.spacing.unit}px ${theme.spacing.unit}px var(--default-dark-grey-color)`,
            },
        },
    },
});

export default styles;
