const styles = theme => ({
    container: {
        paddingTop: theme.spacing.unit,
        marginBottom: -theme.spacing.unit,
    },
    button: {
        textTransform: 'none',
        padding: 0,
        fontSize: 15,
        lineHeight: 1,
        color: theme.palette.primary.main,

        '&:hover': {
            color: theme.palette.text.secondary,
            backgroundColor: 'transparent',

            '& $buttonIcon': {
                color: theme.palette.text.primary,
            },
        },
    },
    buttonIcon: {
        marginRight: theme.spacing.unit / 2,
    },
});

export default styles;
