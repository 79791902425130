import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { animated } from 'react-spring';
import { useAnimationsContext } from 'components/ChatContainer/ChatContainer';
import styles from './HeaderStyles';

function Header({ className, classes, ...headerAnimationProps }) {
    const { horizontalDividerAnimationProps } = useAnimationsContext();

    return (
        <div className={className} {...headerAnimationProps}>
            <Typography component="h1" variant="h5" className={classes.logo}>Craftigence</Typography>
            <animated.div style={horizontalDividerAnimationProps} className={classes.divider} />
        </div>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
};

export default withStyles(styles)(Header);
