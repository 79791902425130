import { useEffect } from 'react';
import { useWs } from 'components/WebsocketProvider/WebsocketProvider';
import {
    ACTION_IDENTITY,
    ACTION_DELIVERED,
    ACTION_MESSAGE,
    ACTION_TEMPLATE_MESSAGE, ACTION_MESSAGE_RECEIVED,
} from 'hooks/useChatApi/constants';
import { createActionDispatcher } from 'hooks/useChatApi/utils';

/**
 * Chat api hook.
 *
 * @param onMessage - on new message from server
 * @param onDelivered - on message delivered
 * @param onIdentitySynced - on chat created
 *
 * @returns {{sendMessage: function, syncIdentity: function, sendTemplateMessage: function}}
 */
export function useChatApi({ onMessage, onDelivered, onIdentitySynced }) {
    const { ws } = useWs();

    useEffect(() => {
        ws.on(ACTION_DELIVERED, createActionDispatcher(onDelivered));
        ws.on(ACTION_MESSAGE, createActionDispatcher(onMessage));
        ws.on(ACTION_IDENTITY, createActionDispatcher(onIdentitySynced));
    }, []);

    function sendReceivedReport(botMessageId) {
        ws.send(ACTION_MESSAGE_RECEIVED, {
            payload: {
                id: botMessageId,
            },
        });
    }

    function sendTemplateMessage(userMessage, botMessage) {
        ws.send(ACTION_TEMPLATE_MESSAGE, {
            payload: {
                userMessage: {
                    id: userMessage.id,
                    chat: userMessage.chat,
                    content: userMessage.content,
                },
                botMessage: {
                    id: botMessage.id,
                    chat: botMessage.chat,
                    intent: botMessage.intent,
                    botCode: 'template',
                },
            },
        });
    }

    /**
     * Send message to ws.
     * @param {{ sender: string, content: string, chat: string }} message
     */
    function sendMessage(message) {
        const { id, chat, content } = message;

        ws.send(ACTION_MESSAGE, {
            payload: {
                id,
                content,
                chat,
            },
        });
    }

    /**
     * Sync identity.
     * @param identity
     */
    function syncIdentity(identity) {
        ws.send(ACTION_IDENTITY, {
            payload: {
                id: identity.id,
            },
        });
    }

    return {
        sendMessage,
        sendTemplateMessage,
        sendReceivedReport,
        syncIdentity,
    };
}
