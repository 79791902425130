const styles = theme => ({
    logo: {
        fontSize: 'var(--font-size-xl)',
        fontWeight: 'bold',
        letterSpacing: '.1em',
        textTransform: 'uppercase',
        [theme.breakpoints.only('md')]: {
            fontSize: 'var(--font-size-l)',
        },
    },
    divider: {
        height: 'var(--divider-thickness)',
        backgroundColor: 'var(--default-grey-color)',
        position: 'absolute',
        bottom: 0,
        left: 0,
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
});

export default styles;
