import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { MessageType } from 'types/MessageType';
import { MESSAGE_CLIENT, MESSAGE_DELIVERED, MESSAGE_FAILED } from 'hooks/useMessages/constants';
import Answer from 'components/ChatContainer/Chat/ChatHistory/Message/Answer/Answer';
import styles from './MessageStyles';
import MessageStatus from './MessageStatus/MessageStatus';


function Message({
    message,
    classes,
    isChatOwnerMessage,
    handleResend,
    isMainElement,
    forwardedRef,
}) {
    const isDelivered = (
        MESSAGE_DELIVERED === message.status || message.status === MESSAGE_CLIENT
    );

    let messageColor = 'default';

    if (isChatOwnerMessage) {
        messageColor = isDelivered ? 'primary' : 'secondary';
    }

    const { intent } = message;

    const content = message.content || <Answer intent={intent} />;

    const Wrapper = isMainElement ? 'article' : React.Fragment;

    return (
        <div
            ref={forwardedRef}
            className={`${classes.container} ${isChatOwnerMessage ? classes.container_ownerUser : classes.container_ownerSystem}`}
        >
            <Wrapper>
                <Chip
                    label={content}
                    className={classes.message}
                    color={messageColor}
                    classes={isChatOwnerMessage
                        ? {
                            root: classes.message_ownerUser,
                            label: classes.messageText_ownerUser,
                        }
                        : {
                            root: classes.message_ownerSystem,
                            label: classes.messageText_ownerSystem,
                        }}
                    role="presentation"
                />
            </Wrapper>
            {isChatOwnerMessage && (
                <MessageStatus
                    isDelivered={isDelivered}
                    handleResend={() => handleResend(message.id)}
                    isFailed={message.status === MESSAGE_FAILED}
                />
            )}
        </div>
    );
}

Message.propTypes = {
    isMainElement: PropTypes.bool,
    forwardedRef: PropTypes.object,
    message: MessageType.isRequired,
    handleResend: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    isChatOwnerMessage: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Message);
