import React from 'react';
import PropTypes from 'prop-types';
import { INTENT_ANSWERS, DEFAULT_INTENT } from 'bot-knowledge';

export default function Answer({ intent }) {
    const IntentAnswer = INTENT_ANSWERS[intent] || INTENT_ANSWERS[DEFAULT_INTENT];

    return <IntentAnswer />;
}

Answer.propTypes = {
    intent: PropTypes.string,
};
